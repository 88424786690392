var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('NAME'),"label-for":"id-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-name","maxlength":"300","placeholder":_vm.$t('NAME')},model:{value:(_vm.content.name),callback:function ($$v) {_vm.$set(_vm.content, "name", $$v)},expression:"content.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('REFERENCE_ID'),"label-for":"id-reference-number"}},[_c('b-form-input',{attrs:{"id":"id-reference-number","maxlength":"45","placeholder":_vm.$t('REFERENCE_ID')},model:{value:(_vm.content.externalReference),callback:function ($$v) {_vm.$set(_vm.content, "externalReference", $$v)},expression:"content.externalReference"}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CATEGORY'),"label-for":"id-category"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CATEGORY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-category","placeholder":_vm.$t('SELECT_CATEGORY'),"label":"name","clearable":false,"options":_vm.allCategories},model:{value:(_vm.content.tag),callback:function ($$v) {_vm.$set(_vm.content, "tag", $$v)},expression:"content.tag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.content.id===0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COPIED_FROM'),"label-for":"id-content-copy"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-content-copy","placeholder":_vm.$t('SELECT_CONTENT'),"label":"name","clearable":false,"options":_vm.allContents},on:{"input":function($event){return _vm.handleChangeContent($event)}},model:{value:(_vm.copyContent),callback:function ($$v) {_vm.copyContent=$$v},expression:"copyContent"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('STATUS'),"label-for":"id-status"}},[_c('validation-provider',{attrs:{"name":_vm.$t('STATUS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-status","placeholder":_vm.$t('SELECT_STATUS'),"label":"name","clearable":false,"options":_vm.allStatus},model:{value:(_vm.contentStatus),callback:function ($$v) {_vm.contentStatus=$$v},expression:"contentStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CONTENT_TYPE'),"label-for":"id-contentType"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CONTENT_TYPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-contentType","placeholder":_vm.$t('CONTENT_TYPE'),"clearable":false,"label":"name","options":_vm.allContentTypes},model:{value:(_vm.contentType),callback:function ($$v) {_vm.contentType=$$v},expression:"contentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FREQUENCY'),"label-for":"id-frequency"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FREQUENCY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-frequency","placeholder":_vm.$t('SELECT_FREQUENCY'),"clearable":false,"label":"name","options":_vm.allFrequencies},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('SELECT_COMPANY'),"label":"name","clearable":false,"options":_vm.allCompanies},on:{"input":function($event){return _vm.handleChangeCompany($event)}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Project'),"label-for":"id-project"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Project'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-project","placeholder":_vm.$t('SELECT_PROJECT'),"label":"name","clearable":false,"options":_vm.allProjects},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('START_DATE'),"label-for":"id-strat-date"}},[_c('validation-provider',{attrs:{"name":_vm.$t('START_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-strat-date"},model:{value:(_vm.content.start),callback:function ($$v) {_vm.$set(_vm.content, "start", $$v)},expression:"content.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('END_DATE'),"label-for":"id-end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-end-date"},model:{value:(_vm.content.end),callback:function ($$v) {_vm.$set(_vm.content, "end", $$v)},expression:"content.end"}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DURATION'),"label-for":"id-length"}},[_c('b-form-input',{attrs:{"id":"id-length","type":"number","maxlength":"3","placeholder":_vm.$t('DURATION')},model:{value:(_vm.content.length),callback:function ($$v) {_vm.$set(_vm.content, "length", $$v)},expression:"content.length"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LANGUAGE'),"label-for":"id-language"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LANGUAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id-language","options":_vm.allLanguage,"label":"name","clearable":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"checkbox-is_analyze_individual","name":"checkbox-is_analyze_individual"},model:{value:(_vm.content.analyzeIndividual),callback:function ($$v) {_vm.$set(_vm.content, "analyzeIndividual", $$v)},expression:"content.analyzeIndividual"}},[_vm._v(" "+_vm._s(_vm.$t('AI_INDIVIDUAL_FEEDBACK'))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DESCRIPTION'),"label-for":"id-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"id-description","maxlength":"3000","options":_vm.editorOption},model:{value:(_vm.content.description),callback:function ($$v) {_vm.$set(_vm.content, "description", $$v)},expression:"content.description"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.content.id ? _vm.$t("BUTTON.UPDATE") : _vm.$t("BUTTON.ADD"))+" ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTON.RESET"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }